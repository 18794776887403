import React, { useState } from "react";
import "./ManageApps.scss";
import { Container, Row, Col } from "../../../../../components/Grid/Grid";
import { Typography } from "../../../../../components/__common/Typography/Typography";
import { Checkbox } from "../../../../../components/__common/_controls/Checkbox/Checkbox";
import { Image } from "../../../../../components/__common/Image";
import {
  Button,
  LinkButton,
  TTSButton,
} from "../../../../../components/__common/_controls";
import { SVG_ICONS } from "../../../../../components/__common/Svg/Svg";
import classNames from "classnames";
import Tabs from "../../../../../components/__common/_controls/Tab/Tab";
import { Toast } from "./Toast";
import { ConfirmationModal } from "../../../../../components/ConfirmationModal/ConfirmationModal";
import { useServices } from "../../../../../hooks/services-hooks";
import { useSelector } from "react-redux";
import { ServicesState } from "../../../../../store/reducers/services-reducer";
import { ApplicationState } from "../../../../../store";
import { useEffect } from "react";
import { REQUEST_TYPE } from "../../../../../types/user";
import { ServiceEntity, ServiceStatus } from "../../../../../types/services";
import { UtilsDateTime } from "../../../../../utils/UtilsDateTIme";
import Loading from "../../../../../assets/images/gif/sdap-loading.json";
import { UtilsRequest } from "../../../../../utils/UtilsRequest";
import { MyDataState } from "../../../../../store/reducers/mydata-reducer";
import { ROUTES } from "../../../../../configs/routes";
import Modal from "../../../../../components/__common/Modal/Modal";
import { Trans, useTranslation } from "react-i18next";
import { LayoutState } from "../../../../../store/reducers/layout-reducer";
import { UserState } from "../../../../../store/reducers/user-reducer";
import { UtilsKeyboardAccessibility } from "../../../../../utils/UtilsKeyboardAccessibility";
import FocusTrapping from "../../../../../components/__hoc/FocusTrapping";
import Lottie from "lottie-react";

export const ManageApps: React.FC = () => {
  const { t } = useTranslation();
  const { loadServices, selectedServices, setSelectedServices } = useServices();
  const appServiceState = useSelector<ApplicationState, ServicesState>(
    (state) => state.services
  );
  const appMyDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );
  const appLayoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );
  const appUserState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );

  const { services, isServicesLoading } = appServiceState;
  const { requestNumber, isRequestPending } = appMyDataState;
  const [servicesCount, setServicesCount] = useState<number>(0);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [selectedServicesObject, setSelectedServicesObject] = useState<
    ServiceEntity[]
  >([]);
  const [selectedService, setSelectedService] = useState<ServiceEntity>();
  const [selectedServiceJD, setSelectedServiceJD] = useState<string>("");
  const [requestType, setRequestType] = useState<REQUEST_TYPE>(
    REQUEST_TYPE.DOWNLOAD
  );
  const [isServiceInfoOpen, setIsServiceInfoOpen] = useState<boolean>(false);
  const cachedRequest = UtilsRequest.getRequestInfo();

  const controller = new AbortController();
  useEffect(
    () => {
      if (appUserState.userData.countryCode !== "") {
        loadServices(requestType, controller);
      }

      return () => {
        controller.abort();
      };
    },
    // eslint-disable-next-line
    [
      requestType,
      appLayoutState.languageTrans,
      appUserState.userData.countryCode,
    ]
  );

  useEffect(() => {
    setServicesCount(
      services.filter(
        (service) => service.service_status === ServiceStatus.AVAILABLE
      ).length
    );
    setSelectedServices([]);
    setSelectedServicesObject([]);
  }, [services]);

  if (cachedRequest) {
    UtilsRequest.removeRequestInfo();
  }

  // useEffect for closing confirmation modal if service request throws an error
  useEffect(() => {
    if (appMyDataState.serviceRequestToastError.isError) {
      setConfirmOpen(false);
      setSelectedServices([]);
      setSelectedServicesObject([]);
    }
  }, [confirmOpen, appMyDataState.serviceRequestToastError.isError]);

  useEffect(() => {
    if (requestNumber !== "" && !isRequestPending) {
      setSelectedServices([]);
      setSelectedServicesObject([]);
      window.location.href = `${ROUTES.MYDATA_HISTORY}/${requestNumber}`;
    }
  }, [requestNumber, isRequestPending]);

  const handleCheckboxOnChange = (service: ServiceEntity) => {
    if (selectedServices.includes(service.service_code)) {
      setSelectedServices(
        selectedServices.filter((s) => s !== service.service_code)
      );
      setSelectedServicesObject(
        selectedServicesObject.filter(
          (s) => s.service_code !== service.service_code
        )
      );
    } else {
      setSelectedServices([...selectedServices, service.service_code]);
      setSelectedServicesObject([...selectedServicesObject, service]);
    }
  };

  const handleSelectAllOnChange = () => {
    const filteredServices = services.filter(
      (service) => service.service_status === ServiceStatus.AVAILABLE
    );

    if (selectedServices.length !== filteredServices.length) {
      setSelectedServices(
        filteredServices.map((service) => service.service_code)
      );
      setSelectedServicesObject(filteredServices);
    } else {
      setSelectedServices([]);
      setSelectedServicesObject([]);
    }
  };

  const handleClickServiceLink = (
    e: any,
    service: ServiceEntity,
    jd: number
  ) => {
    e.preventDefault();
    setSelectedService(service);

    if (jd > 0) {
      setSelectedServiceJD(UtilsDateTime.convertJoinedDate(jd));
    }
  };

  return (
    <div className="manage-apps" data-testid="manage-apps">
      {confirmOpen && (
        <FocusTrapping
          isActive={confirmOpen}
          elements="div.modal-body, div.title2, svg, button"
        >
          <ConfirmationModal
            isOpen={confirmOpen}
            setOpen={(val: boolean) => setConfirmOpen(val)}
            variant={requestType}
            serviceList={selectedServicesObject}
          />
        </FocusTrapping>
      )}
      <Container>
        <div className="manage-apps__title-section">
          <Typography variant="h3" ss>
            {t("PRIVACY_MYDATA_MANAGE_TITLE")}
            <TTSButton value={t("PRIVACY_MYDATA_MANAGE_TITLE")} />
          </Typography>
          <div className="manage-apps__title-section__selection">
            <Tabs className="manage-apps__tabs">
              <Tabs.Tab
                buttonTestid="download-tab"
                type="2depth"
                name="manage_tab"
                title={t("PRIVACY_MYREQUEST_TAB_DOWNLOAD")}
                className={
                  requestType !== REQUEST_TYPE.DOWNLOAD && isServicesLoading
                    ? "disabled"
                    : "pointerCursor"
                }
                checked={requestType === REQUEST_TYPE.DOWNLOAD}
                onChange={() => setRequestType(REQUEST_TYPE.DOWNLOAD)}
                disabled={
                  requestType !== REQUEST_TYPE.DOWNLOAD && isServicesLoading
                }
              />
              <Tabs.Tab
                buttonTestid="delete-tab"
                type="2depth"
                name="manage_tab"
                className={
                  requestType !== REQUEST_TYPE.DELETE && isServicesLoading
                    ? "disabled"
                    : "pointerCursor"
                }
                title={t("PRIVACY_MYREQUEST_TAB_DELETION")}
                checked={requestType === REQUEST_TYPE.DELETE}
                onChange={() => setRequestType(REQUEST_TYPE.DELETE)}
                disabled={
                  requestType !== REQUEST_TYPE.DELETE && isServicesLoading
                }
              />
              <Tabs.Tab
                buttonTestid="unsubscribe-tab"
                type="2depth"
                name="manage_tab"
                className={
                  requestType !== REQUEST_TYPE.UNSUBSCRIBE && isServicesLoading
                    ? "disabled"
                    : "pointerCursor"
                }
                title={t("PRIVACY_MYREQUEST_TAB_OPTOUT")}
                checked={requestType === REQUEST_TYPE.UNSUBSCRIBE}
                onChange={() => setRequestType(REQUEST_TYPE.UNSUBSCRIBE)}
                disabled={
                  requestType !== REQUEST_TYPE.UNSUBSCRIBE && isServicesLoading
                }
              />
            </Tabs>
          </div>
        </div>
        {isServicesLoading && (
          <Lottie
            className="manage-apps__loading"
            animationData={Loading}
            loop={true}
            style={{ width: 60, height: 60 }}
          />
        )}
        {!isServicesLoading && (
          <>
            <div className="manage-apps__options-all">
              <Checkbox
                checkboxTestid="select-all"
                title={t("PRIVACY_MYDATA_MANAGE_SELECT_ALL")}
                name="all"
                textClassName="all-text"
                // disabled={servicesCount === 0}
                checked={
                  servicesCount !== 0 &&
                  servicesCount === selectedServices.length
                }
                onChange={handleSelectAllOnChange}
                onKeyPress={handleSelectAllOnChange}
              />
              {/* <Typography component="span" variant="body2" className="count">
                {servicesCount}
              </Typography> */}
            </div>
            <hr />
            <div className="manage-apps__services">
              <Container>
                <Row>
                  {services &&
                    services.map((service) => {
                      const serviceCode = service.service_code;
                      return (
                        <Col sm={12} md={4} key={serviceCode}>
                          <div className="manage-apps__services__checkbox">
                            <Checkbox
                              testId="select-service-checkbox"
                              checkboxTestid="checkbox-keypress"
                              name="services"
                              title=""
                              textClassName="all-text"
                              checked={selectedServices.includes(serviceCode)}
                              disabled={
                                service.service_status === ServiceStatus.BUSY
                              }
                              onChange={() => handleCheckboxOnChange(service)}
                              onKeyPress={() => handleCheckboxOnChange(service)}
                            />
                          </div>
                          <div className="manage-apps__services__info">
                            <Image
                              className="manage-apps__services__info__icon"
                              image={service.service_icon_url}
                              width={64}
                              height={64}
                            />
                            <div>
                              <LinkButton
                                testId="service-link"
                                title={
                                  service.service_name
                                    ? service.service_name
                                    : ""
                                }
                                to="#"
                                icon={SVG_ICONS.GREATE_THAN_LRG}
                                iconPosition="right"
                                size="lg"
                                textClassName="manage-apps__services__info__name"
                                underlined={false}
                                onClick={(e: any) => {
                                  handleClickServiceLink(
                                    e,
                                    service,
                                    service.joined_date
                                  );
                                  setIsServiceInfoOpen(true);
                                }}
                              />
                            </div>
                            <div>
                              <Typography
                                variant="flag"
                                className="manage-apps__services__info__joined"
                              >
                                {service.joined_date > 0 ? (
                                  t("PRIVACY_MYDATA_MANAGE_JOINDATE", {
                                    "%d": UtilsDateTime.convertJoinedDate(
                                      service.joined_date
                                    ),
                                  })
                                ) : (
                                  <>&nbsp;</>
                                )}
                              </Typography>
                            </div>
                            <hr />
                            <div>
                              <Typography
                                variant="body2"
                                className={classNames(
                                  "manage-apps__services__info__text",
                                  {
                                    "manage-apps__services__info__busy":
                                      service.service_status ===
                                      ServiceStatus.BUSY,
                                  }
                                )}
                              >
                                {service.service_status !==
                                ServiceStatus.BUSY ? (
                                  <Trans
                                    values={{
                                      "%s": service.service_name,
                                    }}
                                  >
                                    {service.service_description}
                                  </Trans>
                                ) : (
                                  t("PRIVACY_MYDATA_MANAGE_LABEL_REQUESTING")
                                )}
                              </Typography>
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Container>
            </div>
            {selectedServices.length > 0 && (
              <Toast
                onClickTestid="click-action-button"
                onClickAction={(val: boolean) => setConfirmOpen(val)}
                selectedCount={selectedServices.length}
                requestType={requestType}
                services={selectedServicesObject}
                onRemoveTestid="remove-button"
                onClickRemoveService={(sCode) => handleCheckboxOnChange(sCode)}
                onClose={() => {
                  setSelectedServicesObject([]);
                  setSelectedServices([]);
                }}
              />
            )}
          </>
        )}
      </Container>
      <FocusTrapping
        isActive={isServiceInfoOpen}
        elements="div.modal-body, div.title2, svg, button, input"
      >
        <ServiceInfoModal
          service={selectedService}
          joinedDate={selectedServiceJD}
          requestType={requestType}
          onHide={() => {
            setSelectedService(undefined);
            setSelectedServiceJD("");
            setIsServiceInfoOpen(false);
          }}
          isOpen={isServiceInfoOpen}
        />
      </FocusTrapping>
    </div>
  );
};

interface ServiceInfoModalProps {
  service: ServiceEntity | undefined;
  joinedDate: string;
  requestType: string;
  onHide: () => void;
  isOpen: boolean;
}

const ServiceInfoModal: React.FC<ServiceInfoModalProps> = (props) => {
  const { service, isOpen } = props;
  const { t } = useTranslation();

  const getExtraInfo = () => {
    let description: string = "";

    switch (props.requestType) {
      case REQUEST_TYPE.DOWNLOAD:
        description = service?.service_description_download || "";
        break;
      case REQUEST_TYPE.DELETE:
        description = service?.service_description_delete || "";
        break;
      case REQUEST_TYPE.UNSUBSCRIBE:
        description = service?.service_description_optout || "";
        break;
      default:
        description = "";
        break;
    }

    return (
      description !== "" && (
        <>
          <br />
          <br />

          <Trans
            components={{
              a: (
                // eslint-disable-next-line
                <a target="_blank" />
              ),
            }}
            values={{
              "%s": service?.service_name,
            }}
          >
            {description}
          </Trans>
        </>
      )
    );
  };

  useEffect(() => {
    if (isOpen) {
      UtilsKeyboardAccessibility.setFocus("service-info-modal", {
        preventScroll: true,
      });
    }
  }, [isOpen]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "service-info-tts",
      "pseudo-service-info"
    );
    UtilsKeyboardAccessibility.addTabEventListener(
      "close-service-info-modal",
      "service-info-modal"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "service-info-tts",
        "pseudo-service-info"
      );
      UtilsKeyboardAccessibility.removeTabEventListener(
        "close-service-info-modal",
        "service-info-modal"
      );
    };
  }, []);

  return (
    <Modal
      show={isOpen}
      wrapperClassName="service-info-modal"
      id="service-info-modal"
      tabIndex={0}
      autoHeight
    >
      <Modal.Header
        headertestid="onhide-service-info-modal"
        onHide={() => props.onHide()}
      >
        {service && (
          <>
            <Image
              image={service.service_icon_url}
              width={32}
              height={32}
              className="service-info-modal__icon"
            />
            <Modal.Title ttsButtonId="service-info-tts">
              {service.service_name}
            </Modal.Title>
          </>
        )}
      </Modal.Header>
      <Modal.Body>
        <div className="service-info-modal__description">
          {props.joinedDate !== "" && (
            <Typography
              variant="body2"
              className="service-info-modal__description__join-date"
            >
              Joined{" "}
              <Typography variant="body2" component="span" weight="wBold">
                {props.joinedDate}
              </Typography>
            </Typography>
          )}
          {service && (
            <Trans
              values={{
                "%s": service.service_name,
              }}
            >
              {service.service_description}
            </Trans>
          )}

          {getExtraInfo()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          testId="onhide-service-info-modal-button"
          variant="solid"
          type="primary"
          title={t("PRIVACY_CANCEL_REQUEST_BTN_CLOSE")}
          className="btn-close"
          onClick={() => props.onHide()}
          id="close-service-info-modal"
        />
        <div id="pseudo-service-info" tabIndex={0}></div>
      </Modal.Footer>
    </Modal>
  );
};
