import React, { useEffect, useState } from "react";

import "./ConfirmationModal.scss";
import { Row, Col } from "../../components/Grid/Grid";
import { Typography } from "../../components/__common/Typography/Typography";
import { Image } from "../../components/__common/Image";
import { Svg, SVG_ICONS } from "../../components/__common/Svg/Svg";
import Modal from "../../components/__common/Modal/Modal";
import { Button } from "../../components/__common/_controls";
import { useWindowSize } from "../../hooks/window-hooks";
import { useUserHooks } from "../../hooks/user-hooks";
import { useAuth } from "../../hooks/auth-hooks";
import { REQUEST_TYPE } from "../../types/user";
import { useMyDataHooks } from "../../hooks/mydata-hooks";
import { Trans, useTranslation } from "react-i18next";
import { ServiceEntity } from "../../types/services";
import { UtilsKeyboardAccessibility } from "../../utils/UtilsKeyboardAccessibility";
import { Cookie } from "../../services/storage";
import {
  CLOSE_CONFIRMATION_MODAL,
  COOKIE_GST_ATKN,
} from "../../constants/storage";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { MyDataState } from "../../store/reducers/mydata-reducer";
import Loading from "../../assets/images/gif/sdap-loading.json";
import { replaceUnicodeWithSlash } from "../../utils/UtilsLocale";
import ReactDomServer from "react-dom/server";
import DOMPurify from "dompurify";
import classNames from "classnames";
import Lottie from "lottie-react";

interface Props {
  variant: REQUEST_TYPE;
  isOpen: boolean;
  serviceList: Array<ServiceEntity>;
  setOpen: (val: boolean) => void;
}

export const ConfirmationModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const [disable, setDisable] = useState(false);
  const { variant, isOpen, serviceList, setOpen } = props;
  const { confirmPassword } = useAuth();
  const { isMobile } = useWindowSize();
  const { userData, isDigitalLegacy } = useUserHooks();
  const { confirmRequest, resetServiceRequestErrorToast } = useMyDataHooks();
  const emailMessage = t("PRIVACY_REQUEST_CONFIRMATION_TEXT_EMAIL");

  const getMobileClassByServices = () => {
    if (serviceList.length > 3)
      return "confirmation-modal__services-banner__container__service-alt__scroll";
    else
      return "confirmation-modal__services-banner__container__service-alt__scroll-mini";
  };

  const getContainerClassByServices = () => {
    if (
      !isMobile &&
      variant !== REQUEST_TYPE.DELETE &&
      serviceList.length < 4
    ) {
      return "confirmation-modal__services-banner__container-mini";
    }
    return "confirmation-modal__services-banner__container";
  };

  const getServiceDetails = (service: ServiceEntity) => {
    const transResult = (
      <Trans
        components={{
          a: (
            // eslint-disable-next-line
            <a
              target="_blank"
              href="https://www.samsung.com/us/support/answer/ANS00080583/"
            />
          ),
        }}
        values={{
          "%s": service?.service_name,
          "-%s": service?.service_name,
        }}
      >
        {service.service_deletion_warning}
      </Trans>
    );

    return replaceUnicodeWithSlash(ReactDomServer.renderToString(transResult));
  };

  const getServiceDisplay = () => {
    return (
      <Row className={getContainerClassByServices()}>
        {variant === REQUEST_TYPE.DELETE ? (
          serviceList &&
          serviceList.map((service) => {
            return service.service_deletion_warning !== "" ? (
              <Col
                sm={12}
                md={12}
                key={service.service_code}
                className="confirmation-modal__services-banner__container__service-delete"
              >
                <Image
                  className="confirmation-modal__services-banner__container__service-delete__icon"
                  image={service.service_icon_url}
                  width={64}
                  height={64}
                />
                <div>
                  <Typography
                    variant="body3"
                    className="confirmation-modal__services-banner__container__service-delete__service-name service-name"
                  >
                    {service.service_name}
                  </Typography>
                  <Typography variant="body3">
                    {/* added dom purify to avoid any malicious HTML code/injection since dangerouslySetInnerHTML has been used  */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(getServiceDetails(service)),
                      }}
                    />
                  </Typography>
                </div>
              </Col>
            ) : (
              <Col
                sm={12}
                md={4}
                key={service.service_code}
                className="confirmation-modal__services-banner__container__col w-full"
              >
                <div
                  className={classNames(
                    "confirmation-modal__services-banner__container__service ",
                    {
                      "mw-none": service.service_deletion_warning === "",
                    }
                  )}
                >
                  <Image
                    className="confirmation-modal__services-banner__container__service__icon"
                    image={service.service_icon_url}
                    width={64}
                    height={64}
                  />
                  <Typography variant="body3" className="service-name">
                    {service.service_name}
                  </Typography>
                </div>
              </Col>
            );
          })
        ) : isMobile ? (
          <div className="confirmation-modal__services-banner__container__service-alt">
            <div className={getMobileClassByServices()}>
              {serviceList &&
                serviceList.map((service) => {
                  return (
                    <div
                      className="confirmation-modal__services-banner__container__service"
                      key={service.service_code}
                    >
                      <Image
                        className="confirmation-modal__services-banner__container__service__icon"
                        image={service.service_icon_url}
                        width={64}
                        height={64}
                      />
                      <Typography variant="body3" className="service-name">
                        {service.service_name}
                      </Typography>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : (
          serviceList &&
          serviceList.map((service) => {
            return (
              <Col
                sm={12}
                md={4}
                key={service.service_code}
                className="confirmation-modal__services-banner__container__col"
              >
                <div className="confirmation-modal__services-banner__container__service">
                  <Image
                    className="confirmation-modal__services-banner__container__service__icon"
                    image={service.service_icon_url}
                    width={64}
                    height={64}
                  />
                  <Typography variant="body3" className="service-name">
                    {service.service_name}
                  </Typography>
                </div>
              </Col>
            );
          })
        )}
      </Row>
    );
  };

  useEffect(() => {
    if (isOpen) {
      resetServiceRequestErrorToast();
      sessionStorage.setItem(CLOSE_CONFIRMATION_MODAL, "false");
      UtilsKeyboardAccessibility.setFocus("confirmation-modal");
    }
  }, [isOpen]);

  useEffect(() => {
    UtilsKeyboardAccessibility.addTabEventListener(
      "confirm-request-button",
      "confirmation-modal"
    );

    return () => {
      UtilsKeyboardAccessibility.removeTabEventListener(
        "confirm-request-button",
        "confirmation-modal"
      );
    };
  }, []);

  const appMyDataState = useSelector<ApplicationState, MyDataState>(
    (state) => state.myData
  );

  const closeConfirmationModal = sessionStorage.getItem(
    CLOSE_CONFIRMATION_MODAL
  );

  const { isRequestPending } = appMyDataState;
  const isPending =
    isRequestPending || (disable && closeConfirmationModal !== "true");

  return (
    <Modal
      wrapperClassName="confirmation-modal"
      className="confirmation-modal-wrapper"
      show={isOpen && !appMyDataState.serviceRequestToastError.isError}
      id="confirmation-modal"
      tabIndex={0}
    >
      {isPending ? (
        <div className="loader-holder">
          <Lottie
            className="confirmation__loading"
            animationData={Loading}
            loop={true}
            style={{ width: 60, height: 60 }}
          />
        </div>
      ) : (
        <>
          <Modal.Header
            headertestid="modal-header"
            onHide={() => {
              if (!disable) {
                setOpen(false);
              }
            }}
          >
            <Modal.Title>{t("PRIVACY_REQUEST_CONFIRMATION_TITLE")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {(variant === REQUEST_TYPE.DELETE ||
              variant === REQUEST_TYPE.UNSUBSCRIBE) && (
              <div className="confirmation-modal__prompt">
                <Typography variant="body2">
                  {variant === REQUEST_TYPE.DELETE
                    ? t("PRIVACY_REQUEST_CONFIRMATION_WARNING_DELETE")
                    : t("PRIVACY_MYDATA_USAGE_GUIDE_OPTOUT_TEXT1")}
                </Typography>
              </div>
            )}

            <div className="confirmation-modal__services-banner">
              <Typography variant="title3">
                {variant === REQUEST_TYPE.DOWNLOAD ? (
                  <Trans
                    i18nKey="PRIVACY_REQUEST_CONFIRMATION_TEXT_DOWNLOAD"
                    components={{
                      point700: (
                        // eslint-disable-next-line
                        <span className="confirmation-modal__services-banner__blue" />
                      ),
                    }}
                  />
                ) : variant === REQUEST_TYPE.DELETE ? (
                  <Trans
                    i18nKey="PRIVACY_REQUEST_CONFIRMATION_TEXT_DELETE"
                    components={{
                      point700: (
                        // eslint-disable-next-line
                        <span className="confirmation-modal__services-banner__blue" />
                      ),
                    }}
                  />
                ) : (
                  <Trans
                    i18nKey="PRIVACY_REQUEST_CONFIRMATION_TEXT_OPTOUT"
                    components={{
                      point700: (
                        // eslint-disable-next-line
                        <span className="confirmation-modal__services-banner__blue" />
                      ),
                    }}
                  />
                )}
              </Typography>

              {getServiceDisplay()}

              <div className="confirmation-modal__services-banner__divider">
                <div className="confirmation-modal__services-banner__divider__line" />
                <div className="confirmation-modal__services-banner__divider__badge">
                  <div className="confirmation-modal__services-banner__divider__badge__icon">
                    <Typography
                      variant="body1"
                      className="confirmation-modal__services-banner__divider__badge__icon__symbol"
                    >
                      @
                    </Typography>
                  </div>
                </div>
                <div className="confirmation-modal__services-banner__divider__line" />
              </div>
              <div className="confirmation-modal__services-banner__account">
                <Typography
                  variant="body3"
                  className="confirmation-modal__services-banner__account__warning"
                >
                  {userData.mail
                    ? emailMessage
                    : t("PRIVACY_REQUEST_CONFIRMATION_TEXT_NOEMAIL")}
                </Typography>

                <Typography
                  variant="body1"
                  className="confirmation-modal__services-banner__account__email"
                >
                  {userData.validatedEmail}
                </Typography>
              </div>
            </div>
            <div className="confirmation-modal__info">
              <div className="confirmation-modal__info__title">
                <Svg icon={SVG_ICONS.INFORM} height={24} width={24} />
                <Typography
                  variant="body2"
                  className="confirmation-modal__info__title__info"
                >
                  {t("PRIVACY_REQUEST_CONFIRMATION_TEXT_NOTE")}
                </Typography>
              </div>
              <div className="confirmation-modal__info__title-divider" />
              {variant === REQUEST_TYPE.DOWNLOAD && (
                <ul className="confirmation-modal__ul">
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_DOWNLOAD_TEXT2_SUB2")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_REQUEST_CONFIRMATION_TEXT_NOTE_APPLICATION")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_DOWNLOAD_TEXT3")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_DOWNLOAD_TEXT4")}
                    </Typography>
                  </li>
                </ul>
              )}
              {variant === REQUEST_TYPE.DELETE && (
                <ul className="confirmation-modal__ul">
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT3_SUB2")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_REQUEST_CONFIRMATION_TEXT_NOTE_APPLICATION")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_DELETE_TEXT3_SUB3")}
                    </Typography>
                  </li>
                </ul>
              )}
              {variant === REQUEST_TYPE.UNSUBSCRIBE && (
                <ul className="confirmation-modal__ul">
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_OPTOUT_TEXT3_SUB2")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_OPTOUT_TEXT3_SUB3")}
                    </Typography>
                  </li>
                  <li className="confirmation-modal__li">
                    <Typography variant="body3">
                      {t("PRIVACY_MYDATA_USAGE_GUIDE_OPTOUT_TEXT3_SUB4")}
                    </Typography>
                  </li>
                </ul>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="button-container">
              <Button
                type="secondary"
                title={t("PRIVACY_REQUEST_CONFIRMATION_BTN_CANCEL")}
                onClick={() => setOpen(false)}
                disabled={disable}
                testId="confirmation-cancel-btn"
              ></Button>
            </div>
            <div className="button-container">
              <Button
                title={t("PRIVACY_REQUEST_CONFIRMATION_BTN_REQUEST")}
                onClick={() => {
                  setDisable(true);

                  const arrServices: string[] = [];
                  serviceList.forEach((service) => {
                    arrServices.push(service.service_code);
                  });

                  if (variant === REQUEST_TYPE.UNSUBSCRIBE) {
                    confirmRequest(arrServices, variant, "");
                  } else {
                    // check if guest login or DL login
                    if (Cookie.get(COOKIE_GST_ATKN) || isDigitalLegacy) {
                      confirmRequest(arrServices, variant, "");
                      return;
                    }

                    confirmPassword(serviceList, variant);
                  }
                }}
                disabled={disable}
                id="confirm-request-button"
                testId="confirm-request-button"
              ></Button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};
