import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import "./Layout.scss";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { useI18Next } from "../../i18next";
import { ScrollToTop } from "../__common/ScrollToTop/ScrollToTop";
import { useEffect } from "react";
import { useUserHooks } from "../../hooks/user-hooks";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { EuCookieAlert, NonEuCookieAlert } from "../CookieModal/CookieModal";
import { UserState } from "../../store/reducers/user-reducer";
import { Cookie, LocalStorage } from "../../services/storage";
import {
  COOKIE_AD_ATKN,
  COOKIE_GST_ATKN,
  EMAIL_LOGIN_VERIFICATION_RESULT,
  REDIRECT,
  REDIRECT_URI,
  REQUEST_NUMBER,
} from "../../constants/storage";
import { useQuery } from "../../hooks/query-hooks";
import { HelpTipsPoupup } from "../HelpTipsPopup/HelpTipsPopup";
import { AUTHENTICATED_ROUTES, ROUTES } from "../../configs/routes";
import { useLayout } from "../../hooks/layout-hooks";
import { UtilsBrowser } from "../../utils/UtilsBrowser";
import classNames from "classnames";
import { URLS } from "../../constants/urls";
import { LoginModal } from "../LoginModal/LoginModal";
import { EmailLoginModal } from "../EmailLoginModal/EmailLoginModal";
import { OTPModal } from "../OTPModal/OTPModal";
import { useLoginOptionHooks } from "../../hooks/login-option-hooks";
import { LoginOptionState } from "../../store/reducers/login-option-reducer";
import { routeShouldRenderComponent } from "../../utils/UtilsLayout";
import {
  EMAIL_LOGIN_SUPPORTED_COUNTRIES,
  ROUTES_NO_COOKIE_NOTICE,
  ROUTES_NO_HEADER_AND_FOOTER,
  ROUTES_NO_HELP_TIPS,
} from "../../configs/layout";
import FocusTrapping from "../__hoc/FocusTrapping";
import { UtilsUseHistory } from "../../utils/UtilsUseHistory";
import { API_BASE_URL } from "../../constants/api";
import { UtilsUser } from "../../utils/UtilsUser";
import { LayoutState } from "../../store/reducers/layout-reducer";
import { SystemState } from "../../store/reducers/site-reducer";
import { useLocale } from "../../hooks/locale-hooks";
import { useAuth } from "../../hooks/auth-hooks";
import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../../utils/feature-toggle/useFeatureToggle";
import { useSiteSettingsHook } from "../../hooks/site-settings-hooks";
import { SP_SPDL_TICKET_NUMBER } from "../../constants/ticketNumberRegex";
import GoogleAnalytics from "../../utils/GoogleAnalytics";
import { ErrorMaintenance } from "../../pages/nonauth/ErrorPages/ErrorMaintenance";
import { useAdssoLoginGuard } from "../../hooks/adsso-login-guard-hooks";

interface Props {
  children: React.ReactNode;
}

export const Layout: React.FC<Props> = (props) => {
  // third party hooks
  const history = useHistory();
  const { pathname } = useLocation();
  const { i18next } = useI18Next();

  // custom hooks
  const query = useQuery();
  const { logout } = useAuth();
  const { getUserData, loadUserCountryCode, isDigitalLegacy } = useUserHooks();
  const { getUserCountry, setSelectedCountry } = useLayout();
  const {
    toggleLoginModal,
    toggleEmailLoginModal,
    toggleEmailOtpModal,
    removeEmailLoginSession,
    removeGstToken,
    removeRedirectSession,
    handleLoginBasedOnGeoIp,
  } = useLoginOptionHooks();
  const { isDisabled } = useFeatureToggle();
  const { loadLocale } = useLocale();
  const { loadSystemProperties } = useSiteSettingsHook();
  const { ssoLoginRedirect } = useAdssoLoginGuard();

  // states
  const { userCountryCode, isGeoIPLoading } = useSelector<
    ApplicationState,
    UserState
  >((state) => state.user);
  const { isLoginModalOpen, isEmailLoginModalOpen, isEmailOtpModalOpen } =
    useSelector<ApplicationState, LoginOptionState>(
      (state) => state.loginOption
    );
  const { selectedCountry } = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const { isLoading: siteSettingIsLoading } = useSelector<
    ApplicationState,
    SystemState
  >((state) => state.system);

  // variables
  const redirectUri = Cookie.get(REDIRECT_URI);
  const redirectUrlParams = query.get("redirect");
  const requestNumberCookie = Cookie.get(REQUEST_NUMBER);
  const { go } = UtilsUseHistory();
  const isEmailLoginOngoing = isEmailLoginModalOpen || isEmailOtpModalOpen;
  const isLoginAttemptOngoing =
    isEmailLoginModalOpen || isEmailOtpModalOpen || isLoginModalOpen;
  const redirectToChinaLocalSite =
    userCountryCode === "CN" &&
    !UtilsUser.isUserLoggedIn() &&
    pathname !== ROUTES.DIGITAL_LEGACY_AUTH;

  const handleChinaGeoipRedirect = () => {
    if (UtilsUser.isUserLoggedIn() && isDigitalLegacy) return;

    if (redirectToChinaLocalSite) {
      window.location.replace(URLS.CHINA_LOCALSITE);
    }
  };

  useEffect(() => {
    if (
      !isDisabled(DISABLED_FEATURES.ADSSO_SITE_RESTRICTION) &&
      !Cookie.get(COOKIE_AD_ATKN)
    ) {
      ssoLoginRedirect();
    }

    if (
      !isDisabled(DISABLED_FEATURES.UNDER_MAINTENANCE) &&
      pathname !== ROUTES.ERROR_MAINTENANCE
    ) {
      window.location.replace(ROUTES.ERROR_MAINTENANCE);
    }

    // Redirect to designated page after logging in
    if (redirectUri && query.get("success")) {
      window.location.replace(`${API_BASE_URL}${Cookie.get(REDIRECT_URI)}`);
      Cookie.remove(REDIRECT_URI);
    }

    if (pathname === ROUTES.OLD_ABOUT_GRPS) {
      history.replace(ROUTES.ABOUT_GRPS);
    }

    if (!isDisabled(DISABLED_FEATURES.SITE_SETTINGS)) loadSystemProperties();
  }, []);

  useEffect(() => {
    if (!userCountryCode) return;
    handleChinaGeoipRedirect();
    loadLocale();

    const country = getUserCountry();
    setSelectedCountry(country);
  }, [userCountryCode]);

  useEffect(() => {
    if (siteSettingIsLoading) return;

    loadUserCountryCode();
    removeEmailLoginSession();
    if (UtilsUser.isUserLoggedIn()) {
      getUserData();
    }
  }, [siteSettingIsLoading]);

  useEffect(() => {
    // Send page views to Google Analytics
    GoogleAnalytics.initialize(window.location.host);
    if (GoogleAnalytics.isInitialized) GoogleAnalytics.sendPageView(pathname);
  }, [pathname]);

  useEffect(() => {
    if (!redirectUrlParams) return;

    // This will validate first if the params.requestNumber is a valid format, if not valid it will redirect to ERROR 404 page
    if (
      requestNumberCookie &&
      !requestNumberCookie.match(SP_SPDL_TICKET_NUMBER) &&
      !UtilsUser.isUserLoggedIn()
    ) {
      Cookie.remove(REQUEST_NUMBER);
      history.replace(ROUTES.ERROR_404);
      return;
    }
    // to handle deep links with request details, we will check if it includes "/my-data"
    // this would also affect routes like "/my-data" and "/my-data/history"
    if (
      AUTHENTICATED_ROUTES.includes(redirectUrlParams) ||
      redirectUrlParams.includes(ROUTES.MYDATA)
    ) {
      sessionStorage.setItem(REDIRECT, redirectUrlParams);

      handleLoginBasedOnGeoIp();
    }
    // cleanup function for triggering loginModal
    return () => {
      window.onpopstate = () => {
        window.history.replaceState(null, "", "/");
        removeRedirectSession();
        Cookie.remove(REQUEST_NUMBER);
        Cookie.remove(REDIRECT_URI);
        toggleLoginModal({
          isLoginModalOpen: false,
        });
      };
    };
  }, [redirectUrlParams]);

  useEffect(() => {
    window.onpopstate = () =>
      setTimeout(function () {
        if (isEmailLoginOngoing || isLoginModalOpen) {
          toggleEmailOtpModal(false);
          toggleLoginModal({ isLoginModalOpen: false });
          toggleEmailLoginModal(false);
          go(-1);
        }
      }, 0);
  }, [isEmailLoginOngoing, isLoginModalOpen]);

  useEffect(
    () => {
      const emailLoginVerificationResult = LocalStorage.get(
        EMAIL_LOGIN_VERIFICATION_RESULT
      );
      const isVerifying =
        emailLoginVerificationResult &&
        emailLoginVerificationResult === "false";

      if (isVerifying && !isEmailLoginOngoing) {
        removeGstToken();
      }

      if (Cookie.get(COOKIE_GST_ATKN) === undefined) {
        localStorage.removeItem(EMAIL_LOGIN_VERIFICATION_RESULT);
      }
    },
    // eslint-disable-next-line
    [isEmailLoginOngoing]
  );

  useEffect(() => {
    if (!isLoginAttemptOngoing) {
      removeRedirectSession();
    }
  }, [isLoginAttemptOngoing]);

  // Check if user is redirected back to home page after attempting
  // to directly access an authenticated route (deep link)
  useEffect(() => {
    if (!selectedCountry.code || !userCountryCode) return;
    // CHECK IF THE NEW selectedCountry doesn't support email login
    if (
      UtilsUser.isEmailLogin() &&
      !EMAIL_LOGIN_SUPPORTED_COUNTRIES.includes(selectedCountry.code)
    ) {
      // remove email session
      logout();
      removeGstToken();
      sessionStorage.setItem(REDIRECT, ROUTES.MYDATA);
    }
  }, [userCountryCode, selectedCountry]);

  return i18next.isInitialized && !redirectToChinaLocalSite ? (
    <div className="body" data-testid="layout">
      {!UtilsBrowser.isInternetExplorer() &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_HEADER_AND_FOOTER) &&
        isDisabled(DISABLED_FEATURES.UNDER_MAINTENANCE) && <Header />}
      {!UtilsBrowser.isInternetExplorer() &&
        !isGeoIPLoading &&
        userCountryCode &&
        !routeShouldRenderComponent(pathname, ROUTES_NO_COOKIE_NOTICE) && (
          <>
            <NonEuCookieAlert />
            <EuCookieAlert />
          </>
        )}

      {!UtilsUser.isUserLoggedIn() && (
        <FocusTrapping
          isActive={isLoginModalOpen}
          elements="button, div.title2, div.modal-body"
        >
          <LoginModal
            headertestid="loginmodal-onclose-button"
            show={isLoginModalOpen}
            onClose={() => {
              toggleLoginModal({ isLoginModalOpen: false });
            }}
          />
        </FocusTrapping>
      )}

      {isEmailLoginModalOpen && (
        <FocusTrapping
          isActive={isEmailLoginModalOpen}
          elements="input, button#cancel-request-button, #confirm-request-button, button.modal-header__close__btn, div.modal-body, div.title2"
        >
          <EmailLoginModal
            isOpen={isEmailLoginModalOpen}
            setIsOpen={toggleEmailLoginModal}
          />
        </FocusTrapping>
      )}

      {isEmailOtpModalOpen && (
        <FocusTrapping
          isActive={isEmailOtpModalOpen}
          elements="input, div.modal-body, div.title2, button"
        >
          <OTPModal
            isOpen={isEmailOtpModalOpen}
            setIsOpen={toggleEmailOtpModal}
          />
        </FocusTrapping>
      )}

      <main
        id="main-body"
        className={classNames({
          "snap-scroll": pathname === ROUTES.ROOT,
        })}
      >
        {!UtilsBrowser.isInternetExplorer() &&
          !routeShouldRenderComponent(pathname, ROUTES_NO_HELP_TIPS) && (
            <HelpTipsPoupup />
          )}

        <div className="layout">
          {isDisabled(DISABLED_FEATURES.UNDER_MAINTENANCE) ? (
            props.children
          ) : (
            <ErrorMaintenance />
          )}
        </div>
        <ScrollToTop />
        {!UtilsBrowser.isInternetExplorer() &&
          !routeShouldRenderComponent(pathname, [ROUTES.ADSSO_LOGOUT]) &&
          isDisabled(DISABLED_FEATURES.UNDER_MAINTENANCE) && <Footer />}
      </main>
    </div>
  ) : (
    <div data-testid="No-access"></div>
  );
};
