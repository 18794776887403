import { useDispatch, useSelector } from "react-redux";
import langs from "langs";
import { actionSetLanguage } from "../store/actions/layout-actions";
import { SetLanguage } from "../types/layout";

import {
  COUNTRY,
  COUNTRY_CODE,
  LANGUAGE,
  LANGUAGE_CODE,
  LANGUAGE_TRANS,
  PP_LANGUAGE,
  PP_REGION,
  REGION,
} from "../constants/storage";
import { ApplicationState } from "../store";
import { UserState } from "../store/reducers/user-reducer";
import { Cookie } from "../services/storage";
import { useEffect } from "react";
import { UtilsLocale } from "../utils/UtilsLocale";
import { Country } from "../types/countries";
import {
  DEFAULT_LANGUAGE_CODE,
  DEFAULT_LANGUAGE_TRANS,
  RTL_LANGUAGE_CODES,
  SUPPORTED_LANGUAGES,
} from "../constants/locale";
import { LayoutState } from "../store/reducers/layout-reducer";
import { Languages } from "../data/languages";
import { useCookie } from "./cookie-hooks";
import { useI18Next } from "../i18next";
import { actionSetMyDataHistoryLoaded } from "../store/actions/mydata-actions";
import { actionSetPrivacyLanguageRegion } from "../store/actions/privacy-actions";
import { DETECTED } from "../constants/site-settings";
import { SystemState } from "../store/reducers/site-reducer";

import {
  DISABLED_FEATURES,
  useFeatureToggle,
} from "../utils/feature-toggle/useFeatureToggle";

export const useLocale = () => {
  const dispatch = useDispatch();
  const { isFCAllowed } = useCookie();
  const userState = useSelector<ApplicationState, UserState>(
    (state) => state.user
  );
  const layoutState = useSelector<ApplicationState, LayoutState>(
    (state) => state.layout
  );

  const siteSettings = useSelector<ApplicationState, SystemState>(
    (state) => state.system
  );
  const { i18next } = useI18Next();
  const { isDisabled } = useFeatureToggle();
  useEffect(() => {
    if (siteSettings.isLoading) return;
    loadLocale();
  }, [
    userState.userRegion.isSAFromUSA,
    layoutState.languageTrans,
    siteSettings.isLoading,
  ]);

  useEffect(() => {
    if (layoutState.languageCode !== "bg")
      document.documentElement.setAttribute("lang", layoutState.languageCode);
    else document.documentElement.setAttribute("lang", "");

    if (layoutState.isRTL) document.documentElement.setAttribute("dir", "rtl");
    else document.documentElement.setAttribute("dir", "ltr");
  }, [layoutState.isRTL, layoutState.languageCode]);

  const setLanguage = (props: SetLanguage) => {
    // const properLanguageTrans =
    //   !userState.userRegion.isSAFromUSA &&
    //   props.languageTrans === "en-rUS" &&
    //   !userState.isGetUserDataLoading
    //     ? "en"
    //     : props.languageTrans;

    dispatch(
      actionSetLanguage({ ...props, languageTrans: props.languageTrans })
    );

    const ppLang = {
      language: `${props.languageCode}-${props.countryCode}`,
      region: props.region,
    };

    dispatch(actionSetPrivacyLanguageRegion(ppLang));

    dispatch(actionSetMyDataHistoryLoaded(false));

    if (isFCAllowed) {
      Cookie.set(LANGUAGE_TRANS, props.languageTrans, { expires: 365 });
      Cookie.set(REGION, props.region, { expires: 365 });
      Cookie.set(COUNTRY, props.country, { expires: 365 });
      Cookie.set(COUNTRY_CODE, props.countryCode, { expires: 365 });
      Cookie.set(LANGUAGE, props.language, { expires: 365 });
      Cookie.set(LANGUAGE_CODE, props.languageCode, { expires: 365 });
      Cookie.set(PP_LANGUAGE, `${ppLang.language}`, { expires: 365 });
      Cookie.set(PP_REGION, `${ppLang.region}`, { expires: 365 });
    }
  };

  interface GetLanguage {
    locale?: string;
    country?: string;
  }

  const getLanguage = async (): Promise<GetLanguage> => {
    let locale = Cookie.get(LANGUAGE_CODE);
    let country = Cookie.get(COUNTRY_CODE);

    if (locale) {
      return {
        locale,
        country,
      };
    }

    if (
      siteSettings.sysProps.SITE_COUNTRY === DETECTED ||
      isDisabled(DISABLED_FEATURES.SITE_SETTINGS)
    ) {
      // Get language of browser
      locale = navigator.language;

      // If the value of "locale" is "en-US"
      // Splitting by "-" would result in the following:
      //   - ["en", "US"]
      const aLocaleInfo = locale.split("-");

      // The value of "langCode" would be "en". As per the comment above
      const langCode = aLocaleInfo[0];
      locale = langCode;

      // TODO: figure out why locale === "zh" is needed
      if (!country || locale === "zh") {
        // In the case of languages with locales (e.g. en-US), we set "US" as
        // the country.
        country = aLocaleInfo[0];
        if (aLocaleInfo[1]) {
          country = aLocaleInfo[1];
        }

        // Else, for languages like Korea/Japan (ko/ja, respectively), we set
        // them as is since their language and country codes are the same
      }

      if (country === "US" && locale === "es") {
        country = "";
      }
    } else {
      locale = siteSettings.sysProps.SITE_LANGUAGE;
      country = siteSettings.sysProps.SITE_COUNTRY;
    }

    return {
      locale,
      country,
    };
  };

  const loadLocale = async () => {
    let { locale, country } = await getLanguage();

    //TODO add same conditions for other countries that are not supported.
    if (country === "ko") {
      country = "KR";
    }

    let countryData: Country = UtilsLocale.getCountryDataFromJson({
      countryCode: country,
      languageCode: locale,
    }) as Country;

    // let browserLanguageNotSupportedCountry = false;
    if (countryData.name === "" && userState.userCountryCode) {
      // browserLanguageNotSupportedCountry = true;
      countryData = UtilsLocale.getCountryDataFromJson({
        countryCode: userState.userCountryCode,
      }) as Country;
      locale = countryData && countryData.languages[0].code;
      country = userState.userCountryCode;
    }

    country = Cookie.get(COUNTRY_CODE) || country;
    locale = Cookie.get(LANGUAGE_CODE) || locale;

    let languageTrans =
      Cookie.get(LANGUAGE_TRANS) || supportLanguage(locale, country);

    // if (
    //   !userState.userRegion.isSAFromUSA &&
    //   languageTrans === "en-rUS" &&
    //   !browserLanguageNotSupportedCountry
    // ) {
    //   languageTrans = "en";
    // }

    country = UtilsLocale.validateCountry(country) || "";
    if (countryData) {
      updateLanguage(
        { languageCode: locale, languageTrans, countryData },
        true
      );
    }

    i18next.changeLanguage(languageTrans);
  };

  const has = Object.prototype.hasOwnProperty;

  const isEmpty = (prop: any) =>
    prop === null ||
    prop === undefined ||
    (has.call(prop, "length") && prop.length === 0) ||
    (prop.constructor === Object && Object.keys(prop).length === 0);

  const updateLanguage = async (props: any, onMount = false) => {
    let validlanguageCode = validateLanguage(props.languageCode);

    const { countryData } = props;
    if (countryData) {
      // the language trans to be used in reactIntl in "in" should be id
      if (validlanguageCode === "in") validlanguageCode = "id";

      // ReactIntl get language
      //   try {
      //     const reactIntlLocal = await import(`react-intl/locale-data/${validlanguageCode}`);
      //     addLocaleData(reactIntlLocal.default);
      //   } catch (e) {
      //     // if fails convert the language code back to en
      //     validlanguageCode = DEFAULT_LANGUAGE_CODE;
      //   }

      // for languageCode greater than 3 get language code from countryData to fetch correct language name
      if (props.languageCode.length > 3) validlanguageCode = props.languageCode;

      //  Load both default and language trans so default is ready when DID is not present in correct trans lang
      //   const json = await import(`../assets/locales/jsonvalues/${props.languageTrans}.json`);
      // const defaultTrans = DEFAULT_LANGUAGE_TRANS;
      //   const defaultJson = await import(`../assets/locales/jsonvalues/${defaultTrans}.json`);
      const isRTLTemp = RTL_LANGUAGE_CODES.indexOf(validlanguageCode) !== -1;

      if (onMount) {
        const browserLanguage = navigator.language;

        // used actual languageCode instead of validlanguageCode to get correct language name
        // validateLanguage transforms languageCode to 2-letter code example: zh_MO > zh
        dispatch(
          actionSetLanguage({
            region: countryData.region,
            country: countryData.displayName,
            countryCode: countryData.code,
            language: Languages[validlanguageCode],
            languageCode: validlanguageCode,
            languageTrans: props.languageTrans,
            isRTL: isRTLTemp,
            browserLanguage,
          })
        );

        const ppLang = {
          language: `${countryData?.languages[0]?.code}-${countryData?.code}`,
          region: countryData.region,
        };

        const cookiePPLang = Cookie.get(PP_LANGUAGE);
        const cookiePPRegion = Cookie.get(PP_REGION);

        if (
          cookiePPLang &&
          cookiePPLang !== "" &&
          cookiePPLang !== ppLang.language
        ) {
          dispatch(
            actionSetPrivacyLanguageRegion({
              language: cookiePPLang,
              region: cookiePPRegion || "",
            })
          );
        } else {
          dispatch(actionSetPrivacyLanguageRegion(ppLang));
          if (isFCAllowed) {
            Cookie.set(PP_LANGUAGE, `${ppLang.language}`, { expires: 365 });
            Cookie.set(PP_REGION, `${ppLang.region}`, { expires: 365 });
          }
        }
      }

      //   let font = "SamsungOne";

      //   if (languageCode === "ko") font = "SamsungOneKorean";
      //   else if (languageCode === "he") font = "SamsungOneHebrew";
      //   // israel
      //   else if (languageCode === "my") font = "Padauk";
      //   // israel
      //   else if (languageCode === "th") font = "SamsungOneThai";
      //   // thai
      //   else if (
      //     languageCode === "ur" ||
      //     languageCode === "ar" ||
      //     languageCode === "fa"
      //   )
      //     font = "SamsungOneArabic";
      //   // arabic, hebrew, and persian
      //   else if (
      //     languageCode === "ja" ||
      //     languageCode === "zh" ||
      //     languageCode === "zh_MO" ||
      //     languageCode === "zh_CN" ||
      //     languageCode === "zh_HK" ||
      //     languageCode === "zh_TW"
      //   )
      //     font = ""; // no fonts for japan, chinese and thai

      //   // this is to create css font
      //   const css = `body, h1, h2, h3, h4, h5, h6, dl, dd, ol, ul, form, fieldset, legend, input, button, p { font-family: '${font}', sans-serif; }`;
      //   const head = document.head || document.getElementsByTagName("head")[0];
      //   const style = document.createElement("style");

      //   style.type = "text/css";
      //   if (style.styleSheet) {
      //     // This is required for IE8 and below.
      //     style.styleSheet.cssText = css;
      //   } else {
      //     style.appendChild(document.createTextNode(css));
      //   }

      //   head.appendChild(style);

      //   setState({
      //     ...state,
      //     languageJson: json,
      //     defaultLanguageJson: defaultJson,
      //     reactIntlLanguageCode: languageCode,
      //   });
    }
  };

  const validateLanguage = (languageCode?: string): string => {
    if (languageCode) {
      // sanitycheck
      let returnLocale = languageCode;

      if (languageCode.length === 3) {
        // before resorting to default language, we must use first the checker if it exists.
        // sample input is KOR.
        languageCode = languageCode.toLowerCase();
        const oLocaleInfo =
          langs.where("2", languageCode) ||
          langs.where("2B", languageCode) ||
          langs.where("2T", languageCode) ||
          langs.where("3", languageCode);

        // if oLocaleInfo is still not existing, resort to use the default language.
        returnLocale = DEFAULT_LANGUAGE_CODE;

        if ((oLocaleInfo || !isEmpty(oLocaleInfo)) && oLocaleInfo![1]) {
          const twoLetterCountryCode = oLocaleInfo![1];
          returnLocale = twoLetterCountryCode;
        }

        // TODO : set the new language code cookie based on the new data
      } else if (languageCode.length > 3) {
        // To handle the case of languageCode zh_MO
        // to return the string, only use the first part.
        const firstCountryCode = languageCode.split("_")[0];
        returnLocale = firstCountryCode;
      }

      return returnLocale;
    }
    return DEFAULT_LANGUAGE_CODE;
  };

  const supportLanguage: any = (language: string, country?: string) => {
    try {
      if (language.indexOf("zh") >= 0) {
        const aZH = language.replace("_", "-").split("-");
        [language] = aZH;

        if (aZH[1]) {
          const countryCodeZH = aZH[1];
          country = countryCodeZH;

          if (aZH[1] === "SG") {
            country = "CN";
          }
        }
      }

      if (country === "ur") country = "PK";
      if (language.indexOf("es") >= 0) {
        return "es-rES";
      }

      country = country ? `-r${country.toUpperCase()}` : "";
      const lang = language.toLowerCase() + country;

      if (SUPPORTED_LANGUAGES.indexOf(lang) >= 0) {
        return lang;
      }

      if (!country) {
        return DEFAULT_LANGUAGE_TRANS;
      }

      return supportLanguage(language);
    } catch (e) {
      return supportLanguage(language);
    }
  };

  return {
    setLanguage,
    loadLocale,
  };
};
