import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import "./Button.scss";
import { Svg } from "../../Svg/Svg";
import { Typography } from "../../Typography/Typography";
import LoadingGif from "../../../../assets/images/gif/sdap-loading.json";
import Lottie from "lottie-react";
interface Props {
  title: string;
  type?: "primary" | "secondary" | "content";
  variant?: "solid" | "outlined";
  tooltip?: string;
  disabled?: boolean;
  icon?: string;
  iconSize?: number;
  extraIcon?: string;
  iconPosition?: "left" | "right";
  className?: string;
  onClick?: () => void; // Reaquired in implementation
  id?: string;
  isLoading?: boolean;
  testId?: string;
}

export const Button: React.FC<Props> = (props) => {
  const {
    tooltip,
    title,
    type = "primary",
    disabled,
    onClick,
    variant = "solid",
    icon,
    iconSize = 16,
    iconPosition = "right",
    className = "",
    id,
    extraIcon,
    testId,
  } = props;
  const wrapClass = classNames("button", {
    [`button--${type}`]: !!type,
    [`button--${variant}`]: !!variant,
    [`button--${type}--icon`]: !!icon,
    "disabled-button": disabled,
    [className]: !!className,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {icon && iconPosition === "left" ? (
        <button
          title={tooltip}
          className={wrapClass}
          disabled={disabled}
          onClick={handleClick}
          id={id}
          data-testid={testId}
        >
          <span>
            {icon && (
              <Svg
                icon={icon}
                width={iconSize}
                height={iconSize}
                className="left-icon"
              ></Svg>
            )}
            {title}
            {extraIcon && (
              <Svg
                icon={extraIcon}
                width={iconSize}
                height={iconSize}
                className="extra-icon-right"
              ></Svg>
            )}
          </span>
        </button>
      ) : (
        <button
          title={tooltip}
          className={wrapClass}
          disabled={disabled}
          onClick={handleClick}
          id={id}
          data-testid={testId}
        >
          <span>
            {extraIcon && (
              <Svg
                icon={extraIcon}
                width={iconSize}
                height={iconSize}
                className="extra-icon-left"
              ></Svg>
            )}
            {title}
            {icon && (
              <Svg
                icon={icon}
                width={iconSize}
                height={iconSize}
                className="right-icon"
              ></Svg>
            )}
          </span>
        </button>
      )}
    </>
  );
};

interface LinkProps {
  to: string | {};
  title: string;
  underlined?: boolean;
  target?: "_blank" | "_self" | "_parent" | "_top";
  icon?: string;
  iconSize?: number;
  iconPosition?: "left" | "right";
  size?: "sm" | "lg";
  className?: string;
  textClassName?: string;
  onClick?: (e?: any) => void;
  testId?: string;
}
export const LinkButton: React.FC<LinkProps> = (props) => {
  const {
    to,
    title,
    underlined = true,
    target = "_self",
    icon,
    iconSize = 16,
    iconPosition = "right",
    className = "",
    size = "sm",
    textClassName = "",
    onClick,
    testId,
  } = props;

  const wrapClass = classNames("link link--text", {
    [`link--text--underlined`]: !!underlined,
    [`link--text--${size}`]: !!size,
    [`link--text--${size}--icon`]: !!icon,
    [className]: !!className,
  });
  return (
    <>
      <Link
        to={to}
        className={wrapClass}
        target={target}
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
        data-testid={testId}
      >
        {icon && iconPosition !== "left" ? (
          <Typography variant="body2" component="span">
            <span className={textClassName}>
              {title}
              {icon && (
                <Svg
                  icon={icon}
                  width={iconSize}
                  height={iconSize}
                  className="right-icon"
                ></Svg>
              )}
            </span>
          </Typography>
        ) : (
          <Typography
            variant="body2"
            component="span"
            className={textClassName}
          >
            <span>
              {icon && (
                <Svg
                  icon={icon}
                  width={iconSize}
                  height={iconSize}
                  className="left-icon"
                ></Svg>
              )}
              {title}
            </span>
          </Typography>
        )}
      </Link>
    </>
  );
};

interface LoadingButtonProps {
  title: string;
  type?: "primary" | "secondary" | "content";
  variant?: "solid" | "outlined";
  tooltip?: string;
  disabled?: boolean;
  loadingPosition?: "left" | "right";
  className?: string;
  onClick?: () => void;
  id?: string;
  isLoading?: boolean;
  testId?: string;
}

export const LoadingButton: React.FC<LoadingButtonProps> = (props) => {
  const {
    tooltip,
    title,
    type = "primary",
    disabled,
    onClick,
    variant = "solid",
    loadingPosition = "right",
    className = "",
    id,
    isLoading,
    testId,
  } = props;
  const wrapClass = classNames("button", {
    [`button--${type}`]: !!type,
    [`button--${variant}`]: !!variant,
    "disabled-button": disabled,
    [className]: !!className,
  });

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      {loadingPosition === "left" ? (
        <button
          title={tooltip}
          className={wrapClass}
          disabled={disabled}
          onClick={handleClick}
          id={id}
          data-testid={testId}
        >
          <span>
            <div className={"button-text-icon-container"}>
              {isLoading && (
                <Lottie
                  className="loading-button__spinner_left"
                  animationData={LoadingGif}
                  loop={true}
                  style={{ width: 20, height: 20 }}
                />
              )}
              <span>{title}</span>
            </div>
          </span>
        </button>
      ) : loadingPosition === "right" ? (
        <button
          title={tooltip}
          className={wrapClass}
          disabled={disabled}
          onClick={handleClick}
          id={id}
          data-testid={testId}
        >
          <span>
            <div className={"button-text-icon-container"}>
              <span>{title}</span>
              {isLoading && (
                <Lottie
                  className="loading-button__spinner_left"
                  animationData={LoadingGif}
                  loop={true}
                  style={{ width: 20, height: 20 }}
                />
              )}
            </div>
          </span>
        </button>
      ) : (
        <button
          title={tooltip}
          className={wrapClass}
          disabled={disabled}
          onClick={handleClick}
          id={id}
          data-testid={testId}
        >
          <span>
            <div className={"button-text-icon-container"}>
              <span>{title}</span>
              {isLoading && (
                <Lottie
                  className="loading-button__spinner_left"
                  animationData={LoadingGif}
                  loop={true}
                  style={{ width: 20, height: 20 }}
                />
              )}
            </div>
          </span>
        </button>
      )}
    </>
  );
};
